import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";
import styled from "styled-components";
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";

const ContentWithPading = tw(ContentWithPaddingXl)`tw-pb-0`;
const HeadingContainer = tw.div`tw-text-center`;
const Description = tw(SectionDescription)`tw-mx-auto`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-right-0 tw-top-0 tw-h-56 tw-w-56 tw-opacity-15 tw-transform tw-translate-x-2/3 -tw-translate-y-12 tw-text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-opacity-15 tw-transform -tw-translate-x-2/3 tw-text-primary-500`}
`;

const Posts = tw.div`tw-mt-12 tw-flex tw-flex-wrap -tw-mr-3 tw-relative tw-mb-24`;

export default ({
  subheading = "BADTri Instagram",
  heading = "Check out what we've been up to",
  description = "",
}) => {
  return (
    <Container>
      <ContentWithPading>
        <HeadingContainer>
          <SubheadingBase tw={"tw-mb-0 sm:!tw-text-left"}>
            {subheading}
          </SubheadingBase>
          <SectionHeading tw={"tw-mb-0 sm:!tw-text-left"}>
            {heading}
          </SectionHeading>
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <Posts>
          <div class="elfsight-app-df73f35d-1599-4821-8a25-a03553012655"></div>
        </Posts>
      </ContentWithPading>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
