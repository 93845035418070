import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import Header from "components/header/Header";
import { Outlet } from "react-router-dom";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";

const ContentPadding = tw(ContentWithPaddingXl)`tw-pt-16`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentPadding>
          <Outlet />
        </ContentPadding>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
