import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "../misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";

import "slick-carousel/slick/slick.css";
import Tile from "components/Tile/Tile.js";

const Container = tw.div`tw-relative`;
const Content = tw.div`tw-max-w-screen-xl tw-mx-auto tw-pb-20 lg:tw-pb-24`;

const Description = tw.p`tw-max-w-md tw-text-center tw-mx-auto lg:tw-mx-0 lg:tw-text-left lg:tw-max-w-none tw-leading-relaxed tw-text-sm sm:tw-text-base lg:tw-text-lg tw-font-medium tw-mt-4 tw-text-secondary-100`;
const FlexGrid = tw.div`tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-3 sm:tw-grid-cols-2 tw-grid-cols-1 tw-grid-flow-row tw-gap-8`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-right-0 tw-top-0 tw-h-56 tw-w-56 tw-opacity-15 tw-transform tw-translate-x-2/3 -tw-translate-y-12 tw-text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-opacity-15 tw-transform -tw-translate-x-2/3 tw-text-primary-500`}
`;

export default ({}) => {
  return (
    <Container>
      <Content>
        <HeadingInfo
          tw="tw-text-center sm:tw-justify-items-start tw-mb-4"
          subheading={"BADTri Blog"}
          heading={"What our members have to say"}
        />
        <FlexGrid>
          <Tile
            cardImage={"https://content.badtri.org/photos/Izzie1.jpg"}
            cardTitle={"My First 70.3"}
            cardDescription={
              "A Q&A with BADTri member Izzie as she shares the challenges, triumphs, and lessons from completing her first 70.3."
            }
            cardHref={"/blogs/1"}
          />
        </FlexGrid>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

const HeadingInfo = ({ subheading, heading, description, ...props }) => (
  <div {...props}>
    {subheading ? (
      <SubheadingBase tw="tw-mb-0">{subheading}</SubheadingBase>
    ) : null}
    <SectionHeading tw="sm:!tw-text-left">{heading}</SectionHeading>
    {description ? <Description>{description}</Description> : null}
  </div>
);
