import React from "react";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-8.svg";

const ContentWithPadding = tw(ContentWithPaddingXl)`tw-pb-0`;
const HeadingContainer = tw.div`tw-text-center`;

const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`tw-pointer-events-none -tw-z-20 tw-absolute tw-left-0 tw-bottom-0 tw-h-64 tw-w-64 tw-opacity-15 tw-transform -tw-translate-x-2/3 tw-text-primary-500`}
`;
const TwoColumn = tw.div`tw-mb-10`;
const Description = tw.blockquote`tw-text-left tw-text-sm sm:tw-text-lg lg:tw-text-lg xl:tw-text-2xl tw-mt-5`;

export default ({
  subheading = "About BADTri",
  heading = "Our Triathlon Club",
  description = "",
}) => {
  return (
    <Container>
      <ContentWithPadding>
        <HeadingContainer tw={"sm:tw-justify-items-start"}>
          {subheading && (
            <SubheadingBase tw={"tw-mb-0"}>{subheading}</SubheadingBase>
          )}
          {heading && <SectionHeading>{heading}</SectionHeading>}
          {description && <Description>{description}</Description>}
        </HeadingContainer>
        <TwoColumn>
          <Description>
            We are a Bristol triathlon club founded to serve multisport athletes
            of all abilities in the greater Bristol area. Our ethos is one of
            inclusivity and enjoyment. We want to encourage people to make the
            most of their abilities and opportunities, but we also want people
            to have fun in the process. Anyone can be a triathlete - so why not
            come and give us a go!
          </Description>
        </TwoColumn>
      </ContentWithPadding>
      <DecoratorBlob2 />
    </Container>
  );
};
