import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import VideoHero from "components/videoHero/VideoHero";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import PictureGallery from "components/blogs/GridWithFeaturedPost.js";
import AboutUs from "components/features/AboutUs";
import MemberTestimonials from "components/testimonials/MemberTestimonials";
import GetStartedLight from "components/cta/GetStartedLight";

export default () => {
  return (
    <AnimationRevealPage>
      <VideoHero />
      <AboutUs />
      <GetStartedLight />
      <PictureGallery />
      <MemberTestimonials></MemberTestimonials>
      <Footer />
    </AnimationRevealPage>
  );
};
