import React from "react";
import { css } from "styled-components/macro";
import tw from "twin.macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings";
import { SectionDescription } from "components/misc/Typography";
import Dots from "./Dots";

const HeadingContainer = tw.div`tw-text-left tw-mb-10`;
const Heading = tw(SectionHeading)`tw-text-left sm:-tw-mt-3`;
const Description = tw(SectionDescription)`tw-text-left tw-mt-0`;
const ImagesContainer = tw.div`tw-flex tw-flex-initial tw-overflow-scroll tw-gap-5 tw-mb-2`;
const Image = tw.img`tw-object-scale-down tw-max-w-[300px]`;
const QuestionHeading = tw.h2`tw-font-extrabold tw-text-2xl`;
const QuestionAnswer = tw.p`tw-mt-2 tw-mb-8 tw-font-light`;

export default () => {
  return (
    <>
      <HeadingContainer>
        <SubheadingBase>BADTri Blog</SubheadingBase>
        <Heading>My First 70.3</Heading>
        <Description>
          A Q&A with BADTri member Izzie as she shares the challenges, triumphs,
          and lessons from completing her first 70.3.
        </Description>
      </HeadingContainer>
      <ImagesContainer>
        <Image
          src="https://content.badtri.org/photos/Izzie2.jpg"
          alt="Izzie completing her Ironman 70.3"
        />
        <Image
          tw="tw-object-cover"
          src="https://content.badtri.org/photos/Izzie1.jpg"
          alt="Izzie completing her Ironman 70.3"
        />
        <Image
          src="https://content.badtri.org/photos/Izzie3.png"
          alt="Izzie completing her Ironman 70.3"
        />
        <Image
          src="https://content.badtri.org/photos/Izzie4.png"
          alt="Izzie completing her Ironman 70.3"
        />
        <Image
          src="https://content.badtri.org/photos/Izzie5.png"
          alt="Izzie completing her Ironman 70.3"
        />
      </ImagesContainer>
      <Dots />
      <QuestionHeading>
        How did you end up in Bristol doing triathlon for a hobby?
      </QuestionHeading>
      <QuestionAnswer>
        I moved to Bristol in December 2022 and joined BAD Tri as soon as we
        moved here. I had previously been part of a local triathlon club in
        Cardiff, which I joined prior to completing my first sprint triathlon at
        Blenheim Palace in May 2022. Unlike lots of triathletes, I have always
        loved swimming being an ex-synchronised swimmer and water polo player.
        When the world closed in 2020, I took up running, and a year later
        decided to take a leap and enter my first triathlon without even owning
        a bike! I work in public health research so spend much of my time at a
        desk, so having a hobby where I am outside and active brings me such
        joy.
      </QuestionAnswer>
      <QuestionHeading>
        When and why did you decide on a 70.3 triathlon?
      </QuestionHeading>
      <QuestionAnswer>
        After joining BAD Tri and meeting the most inspiring group of women I
        decided to challenge myself to doing a 70.3. Many of the women competed
        in regular middle, to iron distance, and seeing girls like me doing
        that, and having a support network to ask lots of questions to, gave me
        the confidence to go for it!
      </QuestionAnswer>
      <QuestionHeading>Which event - and why?</QuestionHeading>
      <QuestionAnswer>
        I entered Swansea 70.3 with a group of other BAD Tri women. The main
        motivation for this race was doing it with a group, as well as the
        convenient location, with a beautiful bike route, and a marina swim.
        With the swim being my strongest discipline, I wanted to minimise any
        risk of it being cancelled due to weather conditions. Being in Swansea
        meant lots of friends and family were able to join the cheer squad on
        the day!
      </QuestionAnswer>
      <QuestionHeading>Tell us about your training plan.</QuestionHeading>
      <QuestionAnswer>
        From Jan 2024 I followed a programmed training plan. Having not ever
        covered the cycling distances, and only ever running one half-marathon,
        I decided to get a coach to guide me. I generally swam once per week,
        ran 3 times, cycled 2 times and did 1 pilates, and one strength session.
        The plan was progressive, and I built up distance, and had a few half
        marathons and Olympic distance events along the way. Every third week I
        would have a de-load week which helped with recovery and the time
        commitment feeling manageable. Having the sessions planned out took a
        lot of the mental load off, which really worked for me.
      </QuestionAnswer>
      <QuestionHeading>
        How easy was it to fit in the training into work etc?
      </QuestionHeading>
      <QuestionAnswer>
        There were times where I felt that there were not enough hours during
        the week, and I relied heavily on my weekends to fit in the longer
        sessions. We welcomed a new puppy to our family, so also juggling time
        to spend time training and playing with Rio was very important to me.
        She was a 10/10 cheerleader on the day &#128021;
      </QuestionAnswer>
      <QuestionHeading>How did BAD Tri help - if at all!</QuestionHeading>
      <QuestionAnswer>
        Giving me the confidence to go for it in the first place! As well as the
        FANTASTIC coached swimming sessions. I also used one of the club coaches
        for my training plan, and they were an invaluable contact for questions
        and guidance.
      </QuestionAnswer>
      <QuestionHeading>Tell us about race day - pre-race.</QuestionHeading>
      <QuestionAnswer>
        The morning of race day was the most beautiful day (see pic of me and
        Rio above!). I had a plan; the cheer squad had a plan, and the morning
        consisted of a very early overnight oats and coffee. Everything was
        already in transition, so I added my nutrition to the bike and got ready
        to race!
      </QuestionAnswer>
      <QuestionHeading>Did the race go to plan?</QuestionHeading>
      <QuestionAnswer>
        Overall, the race went to plan, and I raced my own race! My only goal
        was to have a good day out and complete it! I had rough time goals for
        each discipline, but they were predominantly to guide my nutrition and
        let the cheer squad know where to be! The swim was fantastic, one of my
        most enjoyable ever! The bike was beautiful and hilly! I am so pleased a
        did a recce of the bike loop a few weeks before to know what to expect.
        My bike nutrition strategy was a bit unorthodox, including a mini
        marmite wrap to have something savoury. Unfortunately, I dropped it when
        eating it, which never happened in training, and I didn’t have a backup.
        I ended up having an additional gel in a bid to still have the same
        grams of carbs per hour, but my cycle was faster than I expected (closed
        roads joy!) so I ended up being quite stitchy on the run.
        <br />
        <br />
        Another not to plan part was missing the toilet in T2! I was bursting
        and needed a tampon change (the joy of being a female athlete) and the
        next stop was 2 miles into the run. I had a face like thunder for the
        first 20 minutes of the run!
      </QuestionAnswer>
      <QuestionHeading>What did you eat and drink?</QuestionHeading>
      <QuestionAnswer>
        As someone with a sensitive gut at the best of times, this was something
        I was particularly anxious about! My race was essentially sponsored by
        salty pretzels, M&S sour colin caterpillar sweets and Styrkr nutrition
        (the best!!).
      </QuestionAnswer>
      <QuestionHeading>How does it feel to have done a 70.3?</QuestionHeading>
      <QuestionAnswer>
        I am so proud to have done a 70.3 and am excited for what is to come!
        Its taught me important lessons in resilience, determination and goal
        setting which I have taken into other areas of my life.
      </QuestionAnswer>
      <QuestionHeading>
        What do you know now that you wished you’d know from day one?
      </QuestionHeading>
      <QuestionAnswer>
        You don’t have to be an age category competitive athlete to take part in
        an iron man event!
      </QuestionAnswer>
      <QuestionHeading>What’s next?</QuestionHeading>
      <QuestionAnswer>
        Enjoying training outside is the plan for a little, getting stronger in
        the gym over the winter, and I’ve entered a few fun events for next
        summer. My 2025 A-race is ‘The Roc England’; I can’t wait to swim in
        Lake Windermere and try a different kind of event. I am looking forward
        to having Rio as a training partner to prepare for Scafell Pike!
      </QuestionAnswer>
    </>
  );
};
