import React from "react";
import { css } from "styled-components/macro";
import tw from "twin.macro";

const DotsContainer = tw.div`tw-flex tw-flex-initial tw-overflow-scroll tw-gap-2 tw-mb-10 tw-justify-center`;
const Dot = tw.div`tw-w-2 tw-h-2 tw-bg-gray-400 tw-rounded-full`;

export default () => {
  return (
    <>
      <DotsContainer>
        <Dot />
        <Dot />
        <Dot />
        <Dot />
        <Dot />
      </DotsContainer>
    </>
  );
};
