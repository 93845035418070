import { Button } from "@chakra-ui/react";
import React from "react";

import { css } from "styled-components/macro"; //eslint-disable-line
import tw from "twin.macro";

const CardContainer = tw.div`tw-border-2 tw-border-black tw-rounded-lg tw-bg-white`;
const CardImage = tw.img`tw-h-[200px] tw-w-[100%] tw-object-cover tw-rounded-t-lg`;
const TileTextContent = tw.div`tw-p-5`;
const TileHeading = tw.h3`tw-font-bold tw-text-2xl`;
const TileDescription = tw.p`tw-mb-4`;

export default ({ cardImage, cardTitle, cardDescription, cardHref }) => {
  return (
    <CardContainer>
      <CardImage src={cardImage} />
      <TileTextContent>
        <TileHeading>{cardTitle}</TileHeading>
        <TileDescription>{cardDescription}</TileDescription>
        <Button as={"a"} href={cardHref} colorScheme="red" variant={"solid"}>
          Read more
        </Button>
      </TileTextContent>
    </CardContainer>
  );
};
